<template>
  <div>
    <b-card
      no-body
      class="card-apply-job"
    >
      <job-header
        :profile-error="profileError"
        :profile-valid="profileValid"
        :job="job"
        :job-remaining="jobRemaining"
        :job-closed="jobClosed"
        :time-remaining="timeRemaining"
      />
      <b-card-body>
        <app-collapse>
          <app-collapse-item title="JOB DESCRIPTION">
            <b-card-text
              class="mb-2"
              v-html="job.description"
            >
            </b-card-text>
          </app-collapse-item>
        </app-collapse>

        <div class="apply-job-package bg-light-primary rounded">
          <div v-if="job.job_group">
            <sup class="text-body">
              <small>JOB GROUP</small>
            </sup>
            <h2 class="d-inline mr-25">
              N
            </h2>
          </div>
          <div>
            Requires
            <b-badge
              variant="light-primary"
              pill
            >
              {{ job.certificate }}
            </b-badge>
          </div>
        </div>
      </b-card-body>

      <div class="divider my-2">
        <div class="divider-text">
          Application Details
        </div>
      </div>
      <b-overlay
        id="overlay-background"
        :show="loading"
        variant="transparent"
        :opacity="0.85"
        blur="2px"
        rounded="sm"
      >
        <b-card-body v-if="user">
          <!-- start -->
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar
                size="42"
                :src="user.data.picture"
                :text="
                  avatarText(`${user.data.given_name} ${user.data.family_name}`)
                "
                :variant="`light-primary`"
              />
            </b-media-aside>
            <b-media-body>
              <h5 class="mb-0 mt-1">
                {{ user.data.given_name
                }}{{ user.data.middle_name ? ` ${user.data.middle_name} ` : ' '
                }}{{ user.data.family_name }}
              </h5>
              <small class="text-muted">{{
                user.information.nationalID
              }}</small>
            </b-media-body>
            <div>
              <b-badge
                pill
                :variant="`light-warning`"
                class="text-capitalize mr-1"
              >
                Draft
              </b-badge>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mt-0 sm:hidden"
                pill
                @click.prevent="
                  $router.push({
                    name: 'update-profile',
                  })
                "
              >
                Update Profile
              </b-button>
            </div>
          </b-media>

          <b-card-text class="mt-1">
            <feather-icon
              icon="MapPinIcon"
              class="ml-2"
            />
            {{ user.information.ward }} Ward,
            {{ user.information.sub_county }} Sub County,
            {{ user.information.county }} County
          </b-card-text>

          <b-tabs>
            <b-tab>
              <template #title>
                <feather-icon icon="BookOpenIcon" />
                <span>Education</span>
              </template>

              <b-card-text class="mb-2">
                <b-list-group>
                  <b-list-group-item
                    v-for="(education, index) in user.education"
                    :key="index"
                  >{{ index + 1 }}. {{ education.examination }} -
                    {{ education.institution }} ·
                    <b-badge
                      pill
                      :variant="`light-secondary`"
                      class="text-capitalize"
                    >
                      {{ education.year_started }} -
                      {{ education.year_completed }}
                    </b-badge></b-list-group-item>
                </b-list-group>
              </b-card-text>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="BriefcaseIcon" />
                <span>Experiences</span>
              </template>

              <b-card-text class="mb-2">
                <b-list-group>
                  <b-list-group-item
                    v-for="(experience, index) in user.experience"
                    :key="index"
                  >{{ index + 1 }}. {{ experience.designation }},
                    {{ experience.company }} ·
                    <b-badge
                      pill
                      :variant="`light-secondary`"
                      class="text-capitalize"
                    >
                      {{
                        formatDate(experience.start_date, {
                          month: 'short',
                          day: 'numeric',
                          weekday: 'short',
                        })
                      }}
                      -
                      {{
                        formatDate(experience.end_date, {
                          month: 'short',
                          day: 'numeric',
                          weekday: 'short',
                        })
                      }}
                    </b-badge>
                  </b-list-group-item>
                </b-list-group>
              </b-card-text>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="AtSignIcon" />
                <span>Referees</span>
              </template>

              <b-card-text class="mb-2">
                <b-list-group>
                  <b-list-group-item
                    v-for="(referee, index) in user.referees"
                    :key="index"
                  >{{ index + 1 }}. {{ referee.name }},
                    {{ referee.company }} ·
                    <b-badge
                      pill
                      :variant="`light-secondary`"
                      class="text-capitalize"
                    >
                      {{ referee.phone }}
                    </b-badge>
                  </b-list-group-item>
                </b-list-group>
              </b-card-text>
            </b-tab>
          </b-tabs>

          <div class="form-label-group">
            <b-form-textarea
              id="cover"
              v-model="cover"
              rows="3"
              placeholder="Cover Information (Optional)"
            />
            <label for="cover">Cover Information (Optional)</label>
          </div>

          <small>Attach your documents. You can add or remove documents from your
            <b-link
              :to="{ name: 'update-profile' }"
            >profile page.</b-link></small>
          <v-select
            v-model="documents"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            label="description"
            :options="user.documents"
          />
          <small
            class="text-muted"
          >{{ documents.length }} document{{
            documents.length === 1 ? '' : 's'
          }}
            attached</small>

          <!-- end -->
          <b-card-text class="mb-2">
          </b-card-text>

          <div class="divider my-2">
            <div class="divider-text">
              Optional
            </div>
          </div>
          <div class="mt-2 mb-2 text-center">
            <small>Rate the application process. Your response will be kept
              anonymous and will be used to improve this system.</small>
            <b-form-rating
              v-model="rating"
              no-border
              show-clear
              show-value
              inline
              variant="warning"
            />
          </div>

          <b-alert
            v-if="profileValidity && !checkRequirements"
            variant="primary"
            show
          >
            <div class="alert-body">
              <span><strong>Hey There!</strong> From the education information you
                provided, looks like you don't meet the minimum requirements
                which is a {{ job.certificate }} certificate. You can check out
                the
                <b-link
                  class="text-primary"
                  :to="{ name: 'jobs' }"
                ><strong>other advertised posts</strong></b-link>
                though.</span>
            </div>
          </b-alert>
          <b-alert
            v-if="!jobOpen"
            variant="danger"
            show
          >
            <h4 class="alert-heading">
              Looks like you're too early
            </h4>
            <div class="alert-body">
              <span>
                This job opens on {{ formatDate(job.opening_date) }} and your
                browser date is {{ formatDate(new Date()) }},
                {{ timeRemaining(job.opening_date) }} days remaining. If you
                think it's wrong, set your date then refresh this page.</span>
            </div>
          </b-alert>
        </b-card-body>
        <template #overlay>
          <div class="text-center">
            <b-spinner type="grow" />
            <p class="mt-2">
              Please wait...
            </p>
          </div>
        </template>
      </b-overlay>
      <b-button
        v-if="jobOpen && profileValidity && requirementsMet && user"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        block
        variant="primary"
        :disabled="loading"
        @click.prevent="applyJob"
      >
        <b-spinner
          v-if="loading"
          small
          type="grow"
        />
        <span v-if="!loading"> Submit Application</span>
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BBadge,
  BCardText,
  BCardBody,
  BButton,
  VBPopover,
  BAlert,
  BLink,
  BMediaAside,
  BAvatar,
  BFormRating,
  BListGroup,
  BListGroupItem,
  BTab,
  BTabs,
  BFormTextarea,
  BSpinner,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formatDate, avatarText } from '@core/utils/filter'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import JobHeader from './components/JobHeader.vue'

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    BCardText,
    BCardBody,
    BAlert,
    BLink,
    BMediaAside,
    BAvatar,
    vSelect,
    BFormRating,
    BListGroup,
    BListGroupItem,
    BTab,
    BTabs,
    BFormTextarea,
    BSpinner,
    BOverlay,
    AppCollapse,
    AppCollapseItem,
    JobHeader,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  data() {
    return {
      requirementsMet: false,
      profileValidity: false,
      profileError: '',

      documents: [],
      cover: '',
      rating: null,

      loading: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user
        ? {
            data: this.$store.state.auth.user || {},
            education: this.$store.state.auth.userEducation || [],
            experience: this.$store.state.auth.userExperience || [],
            information: this.$store.state.auth.userInformation || {},
            referees: this.$store.state.auth.userReferees || [],
            documents: this.$store.state.auth.userDocuments
              ? this.$store.state.auth.userDocuments.filter(
                  e => e.endpoint === 'Documents',
                )
              : [] || [],
          }
        : false
    },
    jobOpen() {
      return moment(new Date()).isSameOrAfter(moment(this.job.opening_date))
    },
    jobClosed() {
      return moment(this.job.closing_date).isSameOrBefore(moment(new Date()))
    },
    job() {
      if (this.$route.params.id) {
        const details = this.$store.state.jobs.jobs.find(
          e => e.id === this.$route.params.id,
        )
        if (!details) {
          this.showToast(
            'Error',
            'Job details cannot be found at the moment. Please try again later.',
            'X',
            'danger',
            // eslint-disable-next-line comma-dangle
            15000,
          )
        }
        return details
      }
      return {}
    },
    departments() {
      return this.$store.state.departments.departments || []
    },
    checkRequirements() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.requirementsMet = false
      if (this.job.certificate && this.user) {
        const userCertificates = this.user.education.map(row => row.examination)
        const requirementsMet = userCertificates.includes(this.job.certificate)
        if (!requirementsMet) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.requirementsMet = requirementsMet
          return requirementsMet
        }
      }

      // perform other checks
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.requirementsMet = true
      return true
    },
    profileValid() {
      if (this.user) {
        const { user } = this
        let error = ''

        // checks
        const userCheck = !user.data.email_verified && user.birthdate !== null // TODO: Add email verification check
        const educationCheck = user.education.length >= 2
        const experienceCheck = user.experience.length > 0 // TODO: Add check to calculate the experience programatically
        const informationCheck =
          user.information.county !== null &&
          user.information.married !== null &&
          user.information.nationalID !== null &&
          user.information.religion !== null &&
          user.information.sub_county !== null &&
          user.information.ward !== null &&
          user.information.yearsOfExperience !== null
        const refereesCheck = user.referees.length >= 2

        if (!userCheck) {
          error +=
            'Your Basic Profile should be valid and email address should be verified '
        } else if (!informationCheck) {
          error += 'Your User Information should be valid'
        } else if (!educationCheck) {
          error +=
            'This job requires you to fill in past education and upload their relevant certificates with at least 2 levels'
        } else if (!experienceCheck) {
          error += 'You need to upload experience Details in your profile '
        } else if (!refereesCheck) {
          error +=
            'You need at least 2 referees to guarantee you in your job application '
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.profileValidity = true
          return true
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.profileError = error
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.profileValidity = false
      return false
    },
  },
  methods: {
    formatDate,
    avatarText,
    timeRemaining(d) {
      const date = moment(d)
      return -moment().diff(date, 'days')
    },
    jobRemaining() {
      return moment(new Date()).unix() >= moment(this.job.opening_date)
    },
    jobPassed() {
      return (
        (this.jobRemaining
          ? this.timeRemaining(this.job.closing_date)
          : this.timeRemaining(this.job.opening_date)) < 0
      )
    },
    showToast(title, text, icon, variant, timeout = 10000) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            text,
            icon: `${icon}Icon`,
            variant,
          },
        },
        {
          position: 'bottom-right',
          timeout,
          // eslint-disable-next-line comma-dangle
        },
      )
    },
    handleErrors(err) {
      this.loading = false
      if (err.response) {
        if ('data' in err.response) {
          if ('data' in err.response.data) {
            if (Array.isArray(err.response.data.data)) {
              err.response.data.data.forEach(param => {
                this.showToast(
                  `Error ${err.response.status}`,
                  param.message,
                  'X',
                  'danger',
                  // eslint-disable-next-line comma-dangle
                  15000,
                )
              })
            }
          } else {
            this.showToast(
              `Error ${err.response.status}`,
              `${err.response.data.message}`,
              'X',
              'danger',
              // eslint-disable-next-line comma-dangle
              15000,
            )
          }
        } else {
          this.showToast(
            `Error ${err.response.status}`,
            `${err.response.statusText}`,
            'X',
            'danger',
            // eslint-disable-next-line comma-dangle
            15000,
          )
          // eslint-disable-next-line function-paren-newline
        }
      } else {
        this.showToast(
          'Error',
          `${err.message}`,
          'X',
          'danger',
          // eslint-disable-next-line comma-dangle
          15000,
        )
        // eslint-disable-next-line function-paren-newline
      }
    },
    applyJob() {
      this.loading = true
      this.showToast(
        'Processing',
        'Please wait while we process your application',
        'Bell',
        'warning',
      )
      const documents = this.documents.map(doc => doc.id)
      this.$store
        .dispatch('jobs/applyJob', {
          cover: this.cover,
          documents,
          id: this.$route.params.id,
          snapshot: this.user,
        })
        .then(() => {
          this.showToast(
            'Success',
            'Application sent successfully. Please check your email for a summary.',
            'Check',
            'success',
          )
          setTimeout(() => {
            this.loading = false
          }, 2000)
        })
        .catch(err => this.handleErrors(err))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
